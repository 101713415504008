body {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

svg#canvas {
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;
	cursor: move;
}

#action-bar {
	position: absolute;
	top:0px;
	left: 0px;
	right: 0px;
	padding: 16px;
	width: 100%;
	background: #efefef;
	display: flex;
	align-items: center;
	justify-content: center;

	.action-bar-button {
		margin-right: 16px;
	}
}

rect.element {

		fill: #fff;
		stroke: #888;
		cursor: pointer;
	
}

.selected {
	stroke: rgb(78, 155, 255);
	stroke-width: 5;
}

polygon.selected {
	stroke: rgb(78, 155, 255);
	stroke-width: 5;
	fill: rgb(78, 155, 255);		
}

.relationship {
	cursor: pointer;
	&:hover {

		path {
			stroke-width: 5;
			stroke: orange;	
		}

		polygon {
			stroke: orange;
			fill: orange;	
			stroke-width: 5;
		}
	}

	&.selected {
		path {
			stroke-width: 5;
			stroke: rgb(78, 155, 255);	
		}

		polygon {
			stroke: rgb(78, 155, 255);
			fill: rgb(78, 155, 255);	
			stroke-width: 5;
		}

		text {
			stroke-width: 0;
		}

		circle {
			stroke-width: 2;
			stroke: rgb(78, 155, 255);	
		}
	}

}

rect.debug {
	display: none;
}

circle {
	fill: none;
	stroke: none;
	pointer-events: all;
	cursor: crosshair;

	&:hover {
		stroke: #78aaff;

	}

	&.active {
		stroke: #78aaff;
		fill: #78aaff;
	}

	&.debug {
		fill: red;
		stroke: red;
	}
}

.relationship-type {
	font: 'sans serif';
	font-size: 18px;
	font-weight: 300;
	fill: #000;

	&.positive {

		circle {
			fill: greenyellow;
			opacity: 30%;
		}

		text {
			fill: rgb(67, 121, 25);
		}
	}
	&.negative {
		circle {
			fill: lightcoral;
			opacity: 30%;
		}

		text {
			fill: rgb(224, 62, 62);
		}

	}
}

#entities {
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	left: 0;
	right: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;
	cursor: move;
	pointer-events: none;
}

#inner-entities {
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transform-box: view-box;
	-webkit-transform-origin-x: 0px;
	-webkit-transform-origin-y: 0px;
	-webkit-transform-origin-z: 0px;
}

.entity-text {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12pt;
	font-family: sans-serif;
	border-radius: 25px;

	&.selected {
		color: rgb(78, 155, 255);
	}

	input {
		width: 80%;
		border: none;
		outline: none;
		font-family: sans-serif;
		font-size: 12pt;
		border-radius: 25px;
		text-align: center;
		color: rgb(78, 155, 255);
		background: none;
		pointer-events: all;
	}
}

g.loop {

	&.reinforcing {
		text, polygon {
			fill: #f45a01;
		}	

		circle {
			stroke: #f45a01;
		}
	
	}

	&.balancing {
		text, polygon {
			fill: #089285;
		}	

		circle {
			stroke: #089285;		
		}
	
	}

	circle {
		fill: none;
		stroke-dasharray: 60, 10;
	}

	text {
		font-family: sans-serif;
		font-size: 18px;
	}

}